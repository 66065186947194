/* eslint-disable arrow-parens */
/* eslint-disable semi */
/* eslint-disable quotes */
import store from "@/store";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "dashboard",
      component: () => import("@/views/dashboard/Dashboard.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Dashboard",
        breadcrumb: [
          {
            text: "Dashboard",
            active: true,
          },
        ],
      },
    },
    {
      path: "/home",
      name: "home",
      component: () => import("@/views/home/Home.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Home",
        breadcrumb: [
          {
            text: "Home",
            active: true,
          },
        ],
      },
    },
    {
      path: "/about/us",
      name: "about-us",
      component: () => import("@/views/about_us/About.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "About Us",
        breadcrumb: [
          {
            text: "About Us",
            active: true,
          },
        ],
      },
    },
    {
      path: "/success/story/page",
      name: "success-story-page",
      component: () => import("@/views/success_story_page/SuccessStory.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Success Story Page",
        breadcrumb: [
          {
            text: "Success Story Page",
            active: true,
          },
        ],
      },
    },
    {
      path: "/course/page",
      name: "course-page",
      component: () => import("@/views/course_page/Course.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Course Page",
        breadcrumb: [
          {
            text: "Course Page",
            active: true,
          },
        ],
      },
    },
    {
      path: "/freelancing/page",
      name: "freelancing-page",
      component: () => import("@/views/freelancing/Freelancing.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Course Page",
        breadcrumb: [
          {
            text: "Course Page",
            active: true,
          },
        ],
      },
    },
    {
      path: "/teacher",
      name: "teacher",
      component: () => import("@/views/teacher/Manage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Teacher Manage",
        breadcrumb: [
          {
            text: "Teacher Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/teacher/create",
      name: "teacher-create",
      component: () => import("@/views/teacher/Create.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Teacher Create",
        breadcrumb: [
          {
            text: "Teacher Create",
            active: true,
          },
        ],
      },
    },
    {
      path: "/teacher/edit/:id",
      name: "teacher-edit",
      component: () => import("@/views/teacher/Edit.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Teacher Edit",
        breadcrumb: [
          {
            text: "Teacher Edit",
            active: true,
          },
        ],
      },
    },

    {
      path: "/department",
      name: "course-department",
      component: () => import("@/views/department/Manage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Department Manage",
        breadcrumb: [
          {
            text: "Department Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/course",
      name: "course",
      component: () => import("@/views/course/Manage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Course Manage",
        breadcrumb: [
          {
            text: "Course Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/course/create",
      name: "course-create",
      component: () => import("@/views/course/Create.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Course Add",
        breadcrumb: [
          {
            text: "Course Manage",
          },
          {
            text: "Course Create",
            active: true,
          },
        ],
      },
    },
    {
      path: "/course/edit/:id",
      name: "course-edit",
      component: () => import("@/views/course/Edit.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Course Edit",
        breadcrumb: [
          {
            text: "Course Manage",
          },
          {
            text: "Course Edit",
            active: true,
          },
        ],
      },
    },
    {
      path: "/options",
      name: "options",
      component: () => import("@/views/options/Manage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Options Manage",
        breadcrumb: [
          {
            text: "Options Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/cit/achievement",
      name: "achievement",
      component: () => import("@/views/achievement/Manage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Cit Achievements Manage",
        breadcrumb: [
          {
            text: "Cit Achievements Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/success/story/category",
      name: "success_story_category",
      component: () => import("@/views/success_story_category/Manage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Success Story Category",
        breadcrumb: [
          {
            text: "Success Story Category",
            active: true,
          },
        ],
      },
    },
    {
      path: "/success/story",
      name: "success_story",
      component: () => import("@/views/success_story/Manage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Success Story",
        breadcrumb: [
          {
            text: "Success Story",
            active: true,
          },
        ],
      },
    },
    {
      path: "/review/category",
      name: "review_category",
      component: () => import("@/views/review_category/Manage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Review Category",
        breadcrumb: [
          {
            text: "Review Category",
            active: true,
          },
        ],
      },
    },
    {
      path: "/review",
      name: "review",
      component: () => import("@/views/review/Manage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Review Manage",
        breadcrumb: [
          {
            text: "Review Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/cit/partner",
      name: "cit-partner",
      component: () => import("@/views/cit_partner/Category.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Cit Partner Manage",
        breadcrumb: [
          {
            text: "Cit Partner Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/cit/partner/add/:id",
      name: "cit-partner-add",
      component: () => import("@/views/cit_partner/AddPartner.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Cit Partner Add",
        breadcrumb: [
          {
            text: "Cit Partner Add",
            active: true,
          },
        ],
      },
    },
    {
      path: "/cit/milestone",
      name: "cit-milestone",
      component: () => import("@/views/cit_milestone/Manage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Cit Milestone Manage",
        breadcrumb: [
          {
            text: "Cit Milestone Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/cit/statement",
      name: "cit-statement",
      component: () => import("@/views/cit_statement/Manage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Cit Statement Manage",
        breadcrumb: [
          {
            text: "Cit Statement Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/cit/photo/wall",
      name: "cit-photo-wall",
      component: () => import("@/views/cit_photo_wall/Manage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Cit Photo Wall Manage",
        breadcrumb: [
          {
            text: "Cit Photo Wall Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/career/placement/partner",
      name: "career-placement-partner",
      component: () => import("@/views/career_placement_partner/Manage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Career Placement Partner Manage",
        breadcrumb: [
          {
            text: "Career Placement Partner Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/our/facility",
      name: "our-facility",
      component: () => import("@/views/our_facility/Manage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Our Facility",
        breadcrumb: [
          {
            text: "Our Facility",
            active: true,
          },
        ],
      },
    },
    {
      path: "/our/facility/page",
      name: "our-facility-page",
      component: () => import("@/views/our_facility_page/OurFacility.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Our Facility Page",
        breadcrumb: [
          {
            text: "Our Facility Page",
            active: true,
          },
        ],
      },
    },
    {
      path: "/teacher/page",
      name: "teacher-page",
      component: () => import("@/views/teacher_page/Teacher.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Teacher Page",
        breadcrumb: [
          {
            text: "Teacher Page",
            active: true,
          },
        ],
      },
    },
    {
      path: "/student/feed/back/page",
      name: "student-feed-back-page",
      component: () =>
        import("@/views/student_feed_back_page/StudentFeedBack.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Student Feed Back Page",
        breadcrumb: [
          {
            text: "Student Feed Back Page",
            active: true,
          },
        ],
      },
    },
    {
      path: "/seminar",
      name: "seminar",
      component: () => import("@/views/seminar/Manage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Seminar Manage",
        breadcrumb: [
          {
            text: "Seminar Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/seminar/page",
      name: "seminar-page",
      component: () => import("@/views/seminar_page/Seminar.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Seminar Page Manage",
        breadcrumb: [
          {
            text: "Seminar Page Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/jop/placement/overview",
      name: "job-placement-overview",
      component: () => import("@/views/job_placement_overview/Manage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Job Placement Overview Manage",
        breadcrumb: [
          {
            text: "Job Placement Overview Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/news/page",
      name: "news-page",
      component: () => import("@/views/news_page/NewsPage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "News Page",
        breadcrumb: [
          {
            text: "News Page",
            active: true,
          },
        ],
      },
    },
    {
      path: "/jop/placement/page",
      name: "job-placement-page",
      component: () => import("@/views/job_placement_page/Placement.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Job Placement Page Manage",
        breadcrumb: [
          {
            text: "Job Placement Page Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/faq",
      name: "faq",
      component: () => import("@/views/faq/Manage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "FAQ Manage",
        breadcrumb: [
          {
            text: "FAQ Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/faq/page",
      name: "faq-page",
      component: () => import("@/views/faq_page/FAQ.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "FAQ Page Manage",
        breadcrumb: [
          {
            text: "FAQ Page Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/our/achievement/page",
      name: "our-achievement-page",
      component: () => import("@/views/our_achievement_page/Achievement.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Our Achievement Page Manage",
        breadcrumb: [
          {
            text: "Our Achievement Page Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/our/gallery",
      name: "our-gallery",
      component: () => import("@/views/our_gallery/OurGallery.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Our Gallery Manage",
        breadcrumb: [
          {
            text: "Our Gallery Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/our/gallery/add/:id",
      name: "our-gallery-add",
      component: () => import("@/views/our_gallery/AddOurGalleryImage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Our Gallery Manage",
        breadcrumb: [
          {
            text: "Our Gallery Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/our/gallery/page",
      name: "our-gallery-page",
      component: () => import("@/views/our_gallery_page/Gallery.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Our Gallery Page Manage",
        breadcrumb: [
          {
            text: "Our Gallery Page Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/address",
      name: "address",
      component: () => import("@/views/address/Manage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Address Manage",
        breadcrumb: [
          {
            text: "Address Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/contact/page",
      name: "contact-page",
      component: () => import("@/views/contact_page/Contact.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Contact Manage",
        breadcrumb: [
          {
            text: "Contact Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/header/and/footer",
      name: "header-footer",
      component: () => import("@/views/header_footer/Page.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Header & Footer Manage",
        breadcrumb: [
          {
            text: "Header & Footer Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/roles",
      name: "roles",
      component: () => import("@/views/role/Manage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Role Manage",
        breadcrumb: [
          {
            text: "Role Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/roles/create",
      name: "roles",
      component: () => import("@/views/role/Create.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Role Create",
        breadcrumb: [
          {
            text: "Role Create",
            active: true,
          },
        ],
      },
    },
    {
      path: "/roles/edit/:id",
      name: "roles",
      component: () => import("@/views/role/Edit.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Role Edit",
        breadcrumb: [
          {
            text: "Role Edit",
            active: true,
          },
        ],
      },
    },
    {
      path: "/users",
      name: "users",
      component: () => import("@/views/user/Manage.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "User Manage",
        breadcrumb: [
          {
            text: "User Manage",
            active: true,
          },
        ],
      },
    },
    {
      path: "/users/create",
      name: "users",
      component: () => import("@/views/user/Create.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "User Create",
        breadcrumb: [
          {
            text: "User Create",
            active: true,
          },
        ],
      },
    },
    {
      path: "/users/edit/:id",
      name: "users",
      component: () => import("@/views/user/Edit.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "User Edit",
        breadcrumb: [
          {
            text: "User Edit",
            active: true,
          },
        ],
      },
    },
    {
      path: "/news/category",
      name: "news-category",
      component: () => import("@/views/news_category/NewsCategory.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "News Category",
        breadcrumb: [
          {
            text: "News Category",
            active: true,
          },
        ],
      },
    },
    {
      path: "/news",
      name: "news",
      component: () => import("@/views/news/News.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "News",
        breadcrumb: [
          {
            text: "News",
            active: true,
          },
        ],
      },
    },
    {
      path: "/news/create",
      name: "create-news",
      component: () => import("@/views/news/CreateNews.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Create News",
        breadcrumb: [
          {
            text: "Create News",
            active: true,
          },
        ],
      },
    },
    {
      path: "/news/edit/:id",
      name: "news-edit",
      component: () => import("@/views/news/EditNews.vue"),
      meta: {
        requiredAuth: true,
        pageTitle: "Edit News",
        breadcrumb: [
          {
            text: "Edit News",
          },
          {
            text: "Edit News",
            active: true,
          },
        ],
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        visitor: true,
        layout: "full",
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        visitor: true,
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  if (localStorage.getItem("token")) {
    store.dispatch("CheckAuth");
  }
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiredAuth)) {
    if (!store.getters.loggedIn) {
      next({ name: "login" });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.visitor)) {
    if (store.getters.loggedIn) {
      next({ name: "dashboard" });
    } else {
      next();
    }
  }
});

export default router;
